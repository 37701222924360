<template>
  <div>
    Triggering exception for Sentry test
  </div>
</template>

<script>
export default {
  name: 'SentryTest',
  created() {
    this.sentryTest()
  }
}
</script>
